<template>
    <div>
        <a-modal
        v-model="modals"
        @ok="handleOk"
        
        width:="640px"
        :centered="true"
        
        >
            <div slot="title">
                <div class="titlemodalimport">
                    <img :src="require('@/assets/img/icon/plus.svg')" />
                <h4>Import Data Peserta</h4>
                </div>
            </div>

            <a-button type="template" block @click="Download"> Download Template <img :src="require('@/assets/img/icon/bluedownload.svg')" /></a-button>

            <div  class="uploadFilecustom">
                <a-upload :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload" >
                    <a-button type="uploadimport" > 
                        <img :src="require('@/assets/img/icon/upload.svg')" />
                        <div>Drag and drop file image here to upload <br/> or</div>
                        <div class="buttons">Browse File</div>
                        <div>Only .xlsx file is allowed (File size limit is 25 MB)</div>
                    </a-button>
                </a-upload>
            </div>
            <div v-if="percentage > 0"><a-progress :percent="percentage" /></div>
            <div slot="footer">
                
                <a-button type="primary" @click="Upload">Kirim</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
import $axios from "@/utils/axios.js";
export default {
    data() {
        return {
            modals: false,
            fileList: [],
            disabled:false,
            url: $axios.defaults.baseURL.replace("api", ""),
        }
    },
      mounted() {
    document.body.classList.add("ModalCustom");
  },
  destroyed() {
    document.body.classList.remove("ModalCustom");
    },

    computed: {
        percentage() {
            return this.$store.state.AntrianData.uploadPercentage
        }
  },  
//     watch: {
//         fileList: {
//             handler: function (search) {
//                 console.log(search)
//                 //this.disabled = true
//             },
//             deep:true
//         }
//   },
    methods: {
        Download() {
            this.$store.dispatch('GetDownload').then(response => {
                if (response.data.data != null) {
                    
                    window.open(this.url + "/" + response.data.data);
                }
            })    
        },
        beforeUpload(file) {
      //this.disabled = false;
      const isImages = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (file.size > 25000000) {
        this.$message.error("Max File 25MB");
      } else {
        if (!isImages) {
          this.$message.error("Format yang diizinkan .xlsx");
          return isImages;
        } else {
          //console.log(this.fileList.length);
          if (this.fileList.length > 0) {
            this.$message.error("Maksimal file hanya boleh 1");
          } else {
            this.fileList = [...this.fileList, file];
          }
          return false;
        }
      }
    },
        handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = [];
    },

        Upload() {
            if(this.fileList.length > 0){
            let File = this.fileList[0];

            
        this.$store.dispatch('ImportDataGroup', {
                File,
                id:this.$route.params.id
        }).then(response => {
            this.modals = false
            this.fileList = []
                  this.$router.push({name:'ListQueAntrianData'})
               
            }).catch(err => {
                this.modals = false
                 this.fileList = []
                this.$router.push({ name: 'ListFailedAntrianData' })

                this.$error({
                    title: 'Upload Failed',
                    content: 'Silahkan Download Excel Untuk Melihat Error',
                });
                
            })
            //console.log(File)

            } else {
                this.$message.error('File tidak boleh kosong');
            }
    },
        handleOk() {
            this.modals = false
        },
        handleCancel() {
            this.modals = true            
        },

        Show() {
            this.modals = true
         
        }
    }
}
</script>

<style>
.ModalCustom .ant-modal-content{
    padding:2px 5px !important;
   
   
}

.ModalCustom  .ant-modal-header{
 border-radius: 24px !important;

 }

 .ModalCustom .ant-modal-close-x{
    margin-right: 20px;
 }

</style>
